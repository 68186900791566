import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import close from "../../assets/close.svg";
const ShowRewards = ({ id, setIsModalOpen, isModalOpen }) => {
  const [showCongrats, setShowCongrats] = useState(false);

  useEffect(() => {
    setShowCongrats(true);
  }, [id]);

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const handleDash = () => {
    localStorage.setItem("firstLogin", "false");
    window.location.reload();
  };

  return (
    <Modal isOpen={isModalOpen} contentLabel="Rewards" className="rewards">
      {showCongrats && (
        <>
          <button onClick={handleCloseModal} className="close">
            <img src={close} />
          </button>
          <div className="reward-bg">
            <h2>Congratulations</h2>
            <p id="first">
              You've received <span id="font">25 Donor Money!</span>
            </p>
            <p>As joing bonus.</p>
            <p>Make your first move and start supporting!</p>
            <button style={{ zIndex: "30" }} onClick={handleDash}>
              Your Dashboard
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ShowRewards;
