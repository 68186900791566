import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../common/Footer";
import { BarLoader } from "react-spinners";

const DisputePage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    title: "",
    description: "",
    transId: "",
  });
  const [loading, setLoading] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const { transId } = useParams();

  useEffect(() => {
    const loadData = () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_API}/user`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setFormData({
            firstName: response.data?.data.firstName,
            lastName: response.data?.data.lastName,
            email: response.data?.data.email,
            phone: response.data?.data.phone,
            profileType: response.data?.data.profileType,
            transId: transId,
            title: "Discrepancy in Transaction",
          });
        })
        .catch((error) => {});
    };
    loadData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userToken = localStorage.getItem("userToken");
    let data = JSON.stringify(formData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/dispute`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          title: "Success!",
          confirmButtonColor: "#3ca151",
          text: "Dispute raised successfully!",
          icon: "success",
        });
        window.location.reload();
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          title: "",
          description: "",
          transId: "",
        });
        setLoading(false);
      })
      .catch(async (error) => {
        setLoading(false);

        await Swal.fire({
          title: "Error!",
          confirmButtonColor: "#3ca151",
          text: "Something went wrong! Please try again later.",
          icon: "error",
        });
      });
  };

  return (
    <>
      <div
        style={{ width: "50%", margin: "100px auto", paddingBottom: "20px" }}
      >
        <h1>Get Support</h1>
        <form onSubmit={handleSubmit}>
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          <br />
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
          <br />

          <label>Email:</label>
          <input
            style={{cursor:"not-allowed"}}
            disabled={userToken !== null}
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <br />
          {/*<label>Phone:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <br />
  */}
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          <br />

          {transId && (
            <>
              <label>Transaction Id:</label>
              <input type="text" name="transId" value={transId} disabled />
            </>
          )}
          <br />
          <label>Description:</label>
          <textarea
            rows={"10"}
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
          <br />
          <button type="submit">Submit</button>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px auto",
          }}
        >
          {loading && <BarLoader color="#3ca151" />}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DisputePage;
