import React, { useState } from "react";
import OrgLogo from "../../assets/Organization.png";
import ShowDonations from "../modals/ShowDonations";

const UserCard = ({ user, onViewProfile, onBuyModal }) => {
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + "...";
    }
    return text;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      className="user-card-content"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <div
        className="user-card-logo"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <img
          className="circular-image"
          src={user?.profilePic || OrgLogo}
          style={{ height: "60px" }}
          alt={user.businessName}
        />
        <h5 style={{ margin: "0px 5px", textAlign: "right" }}>
          {user?.profileType === "ngo" ? "NPO" : "Impact Organisation"}
          <br />
        </h5>
      </div>

      <h3 className="user-card-title">
        {truncateText(user?.businessName, 25)}
      </h3>
      <div style={{ flexGrow: 1 }}>
        {user?.about ? (
          <p style={{ fontSize: "14px" }}>{truncateText(user?.about, 90)}</p>
        ) : (
          <div style={{ marginBottom: "46px" }}>&nbsp;</div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <p style={{ color: "#90e172", margin: "0px", fontWeight: "700" }}>
            Balance
          </p>
          <p style={{ margin: "0px", fontWeight: "700" }}>
            {user?.wallet?.walletPoint.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            DM
          </p>
        </div>

        {user?.website && <a href={user?.website || "/"}>Link to website</a>}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "100%",
            marginTop: "auto",
            marginBottom: "10px",
            marginRight: "10px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onViewProfile(user);
          }}
        >
          Donate DM
        </button>
        <button
          id="blue-btn"
          style={{ width: "100%", marginTop: "auto", marginBottom: "10px" }}
          onClick={(e) => {
            e.stopPropagation();
            onBuyModal(user);
          }}
        >
          Buy DM
        </button>
      </div>

      <ShowDonations
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={user._id}
      />
    </div>
  );
};

export default UserCard;
