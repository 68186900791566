import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import OrgLogo from "../../assets/Organization.png";
import axios from "axios";
import Swal from "sweetalert2";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import BuyDMElements from "../Form/BuyDMElements";
import CardFields from "../Form/CardFields";
import AmericanExpressLogo from "../../assets/americanExpress.svg";
import DiscoverLogo from "../../assets/discover.svg";
import MasterCardLogo from "../../assets/mastercard.svg";
import VisaLogo from "../../assets/visa.svg";
import PaypalLogo from "../../assets/paypal.png";
import { BsLockFill } from "react-icons/bs";

const labelStyle = {
  backgroundColor: "#2c2c2c",
  color: "#fff",
  padding: "8px",
  display: "block",
  marginBottom: "4px",
  borderRadius: "4px",
};

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginTop: "2px",
  backgroundColor: "#2c2c2c",
  fontWeight: "bold",
};

function validateForm(billingAddress, isCardPayment) {
  const errors = {};

  if (!billingAddress.email || !/\S+@\S+\.\S+/.test(billingAddress.email)) {
    errors.email = "Valid email is required.";
  }
  if (!billingAddress.firstName.trim()) {
    errors.firstName = "First name is required.";
  }
  if (!billingAddress.lastName.trim()) {
    errors.lastName = "Last name is required.";
  }
  if (!billingAddress.country.trim()) {
    errors.country = "Country is required.";
  }
  if (!billingAddress.addressLine1.trim()) {
    errors.addressLine1 = "Address is required.";
  }
  if (!billingAddress.countryCode.trim()) {
    errors.countryCode = "Country code is required.";
  } else if (!/^\+\d+$/.test(billingAddress.countryCode)) {
    errors.countryCode =
      "Country code must start with '+' followed by numbers.";
  }
  if (!billingAddress.postalCode.trim()) {
    errors.postalCode = "Postal code is required.";
  } else if (!/^\d+$/.test(billingAddress.postalCode)) {
    errors.postalCode = "Postal code must be numeric.";
  }
  if (
    billingAddress.phoneNumber.trim() &&
    !/^\+?\d{10,15}$/.test(billingAddress.phoneNumber)
  ) {
    errors.phoneNumber = "Phone number must be 10-15 digits.";
  }
  if (!billingAddress.amount || parseFloat(billingAddress.amount) <= 0) {
    errors.amount = "Please select gift card!";
  }

  // Card validations
  if (!billingAddress.card.name.trim() && isCardPayment) {
    errors.cardName = "Cardholder name is required.";
  }
  if (!billingAddress.card.no.trim() && isCardPayment) {
    errors.cardNo = "Card number is required.";
  } else if (!/^\d{16}$/.test(billingAddress.card.no) && isCardPayment) {
    errors.cardNo = "Card number must be 16 digits.";
  }
  if (!billingAddress.card.cvv.trim() && isCardPayment) {
    errors.cardCvv = "CVV is required.";
  } else if (!/^\d{3}$/.test(billingAddress.card.cvv) && isCardPayment) {
    errors.cardCvv = "CVV must be 3 digits.";
  }
  if (!billingAddress.card.expiry.trim() && isCardPayment) {
    errors.cardExpiry = "Expiry date is required.";
  } else if (
    !/^\d{4}-(0[1-9]|1[0-2])$/.test(billingAddress.card.expiry) &&
    isCardPayment
  ) {
    errors.cardExpiry = "Expiry date must be in YYYY-MM format.";
  } else {
    const [year, month] = billingAddress.card.expiry.split("-").map(Number);
    const currentDate = new Date();
    const expiryDate = new Date(year, month - 1); // Month is 0-based in Date.

    if (expiryDate < currentDate && isCardPayment) {
      errors.cardExpiry = "Expiry date must be in the future.";
    }
  }

  return errors;
}

const Message = ({
  log,
  amount,
  organization,
  serverError,
  failureMessage,
}) => {
  return (
    <div style={{ textAlign: "center", width: "100%", padding: "20px 0px" }}>
      {log?.transactionStatus === "success" || serverError ? (
        <h3 style={labelStyle}>Successful Transaction of {amount}$!</h3>
      ) : (
        <h3 style={labelStyle}>Transaction of {amount}$ failed!</h3>
      )}

      {failureMessage !== null && (
        <p style={{ ...labelStyle, marginTop: "12px" }}>
          <span style={{ fontWeight: "bold", backgroundColor: "#2c2c2c" }}>
            Failure Reason :
          </span>{" "}
          {failureMessage}
        </p>
      )}

      {log?.transactionStatus === "success" && (
        <>
          <p style={{ ...labelStyle, marginTop: "12px" }}>
            <span style={{ fontWeight: "bold", backgroundColor: "#2c2c2c" }}>
              {(log.amount * 0.8).toFixed(2)} DM
            </span>{" "}
            donated to {organization.businessName}
          </p>
          <p style={labelStyle}>
            <span style={{ fontWeight: "bold", backgroundColor: "#2c2c2c" }}>
              {(log.amount * 0.2).toFixed(2)} DM
            </span>{" "}
            credited to your wallet
          </p>
        </>
      )}
      <button
        style={{ marginTop: "15px", fontWeight: "bold", width: "150px" }}
        onClick={() => window.location.reload()}
      >
        Close
      </button>
    </div>
  );
};

const SubmitPayment = ({
  billingAddress,
  setFormErrors,
  setIsSubmitted,
  setMessage,
  setFailureMessage,
  setServerError,
  isCardPayment,
}) => {
  const [isPaying, setIsPaying] = useState(false);

  const handleClick = async () => {
    setIsPaying(true);
    const errors = validateForm(billingAddress, isCardPayment);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsPaying(false);
      return;
    }
    setFormErrors({});

    try {
      let data = isCardPayment
        ? JSON.stringify({
            amount: billingAddress.amount,
            phoneNo: billingAddress.phoneNumber,
            firstName: billingAddress.firstName,
            lastName: billingAddress.lastName,
            country: billingAddress.country,
            countryCode: billingAddress.countryCode,
            postalCode: billingAddress.postalCode,
            orgId: billingAddress.orgId,
            address: billingAddress.addressLine1,
            email: billingAddress.email,
            card: {
              number: billingAddress.card.no,
              expiry: billingAddress.card.expiry,
              cvv: billingAddress.card.cvv,
              name: billingAddress.card.name,
            },
          })
        : JSON.stringify({
            amount: billingAddress.amount,
            phoneNo: billingAddress.phoneNumber,
            firstName: billingAddress.firstName,
            lastName: billingAddress.lastName,
            country: billingAddress.country,
            countryCode: billingAddress.countryCode,
            postalCode: billingAddress.postalCode,
            orgId: billingAddress.orgId,
            address: billingAddress.addressLine1,
            email: billingAddress.email,
            return_url: `${window.location.href}?status=success`,
            cancel_url: `${window.location.href}?status=failed`,
          });

      const response = await fetch(
        isCardPayment
          ? `${process.env.REACT_APP_LOCAL_API}/card-payment`
          : `${process.env.REACT_APP_LOCAL_API}/paypal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        }
      );

      const orderData = await response.json();

      if (isCardPayment) {
        setIsSubmitted(true);

        if (orderData.isSuccessful) {
          setMessage(orderData.log);
        } else if (!orderData.isSuccessful) {
          setFailureMessage(orderData.message);
        }
      } else {
        window.location.replace(orderData.url);
      }
    } catch (error) {
      console.log(error);
      setFailureMessage("Internal Server Error");
      setServerError(true);
    }
  };

  return !isPaying ? (
    <button
      className={isPaying ? "btn" : "btn btn-primary"}
      style={{ width: "100%" }}
      onClick={handleClick}
    >
      DONATE
    </button>
  ) : (
    <BarLoader color="#3ca151" />
  );
};

const BuyDM = ({ isModalOpen, setIsModalOpen, user, exchangeValue }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isCardPayment, setIsCardPayment] = useState(true);
  const handleClose = () => {
    setIsModalOpen(false);
    window.location.reload();
  };
  const [failureMessage, setFailureMessage] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    amount: "",
    orgId: user._id,
    email: "",
    firstName: "",
    lastName: "",
    country: "",
    addressLine1: "",
    countryCode: "",
    postalCode: "",
    phoneNumber: "",
    card: {
      name: "",
      no: "",
      cvv: "",
      expiry: "",
    },
  });

  const loadUserData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setUserInfo(response.data.data);
        setBillingAddress((prev) => ({
          ...prev,
          email: response.data.data.email,
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          country: response.data.data.country || "",
          countryCode: response.data.data.countryCode
            ? response.data.data.countryCode.toString()
            : "",
          addressLine1: response.data.data.address || "",
          postalCode: response.data.data.postalCode
            ? response.data.data.postalCode.toString()
            : "",
          phoneNumber: response.data.data.phoneNo
            ? response.data.data.phoneNo.toString()
            : "",
        }));
      })
      .catch((error) => {
        if (error.response.status === 500) {
          Swal.fire({
            title: "Session Expired! login Again!",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#3ca151",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            } else {
              localStorage.clear();
            }
          });
        }
      });
  };

  useEffect(() => {
    if (userToken) {
      loadUserData();
    }
  }, []);

  const [paymentError, setPaymentError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState(null);

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Buy DM"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          color: "#fff",
          backgroundColor: "#2c2c2c",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "600px",
          margin: "50px auto",
          maxHeight: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
        },
      }}
    >
      <div
        style={{
          backgroundColor: "#2c2c2c",
        }}
        className="bg-transparent buy-dm-card"
      >
        <button
          onClick={handleClose}
          style={{
            backgroundColor: "#2c2c2c",
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            fontSize: "20px",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          &times;
        </button>

        <div
          style={{
            backgroundColor: "#2c2c2c",
          }}
        >
          <h1>Buy DM</h1>
          <p
            style={{
              backgroundColor: "#2c2c2c",
              margin: 0,
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            100% of Donor Money Gift Card sales go to nonprofits and impact
            enterprises
          </p>
        </div>

        {!isSubmitted && (
          <div style={{ width: "100%" }}>
            <h4
              style={{
                backgroundColor: "#2c2c2c",
                margin: "0",
                marginTop: "12px",
                fontWeight: "bold",
                padding: "10px 0px",
              }}
            >
              Organization
            </h4>
            <div className="buy-org">
              <div className="buy-org-img-text">
                <img
                  className="circular-image"
                  src={user?.profilePic || OrgLogo}
                  style={{ height: "60px" }}
                  alt={user.businessName}
                />
                <h3
                  style={{
                    backgroundColor: "#2c2c2c",
                  }}
                >
                  {user?.businessName} <br />
                  <span
                    style={{
                      color: "#90e172",
                      backgroundColor: "#2c2c2c",
                      fontWeight: "700",
                    }}
                  >
                    Balance:
                  </span>{" "}
                  {user.wallet.walletPoint.toFixed(2)} DM
                </h3>
              </div>
              <div className="badge">
                {user?.profileType === "ngo"
                  ? "Nonprofit"
                  : "Impact Organisation"}
              </div>
            </div>
            <BuyDMElements
              setUserToken={setUserToken}
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              formErrors={formErrors}
              exchangeValue={exchangeValue}
              setFormErrors={setFormErrors}
            />
            <h2 style={labelStyle}>Payment Information</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <button
                onClick={() => setIsCardPayment(true)}
                style={
                  isCardPayment
                    ? { backgroundColor: "black" }
                    : { backgroundColor: "#1E201E" }
                }
              >
                <img
                  style={{ backgroundColor: "transparent" }}
                  height={30}
                  width={40}
                  src={MasterCardLogo}
                />
                <img
                  style={{ backgroundColor: "transparent" }}
                  height={30}
                  width={40}
                  src={VisaLogo}
                />
                <img
                  style={{ backgroundColor: "transparent" }}
                  height={30}
                  width={40}
                  src={DiscoverLogo}
                />
                <img
                  style={{ backgroundColor: "transparent" }}
                  height={30}
                  width={40}
                  src={AmericanExpressLogo}
                />
              </button>
              <button
                onClick={() => setIsCardPayment(false)}
                style={
                  !isCardPayment
                    ? { backgroundColor: "black", padding: "10px 20px" }
                    : { backgroundColor: "#1E201E", padding: "10px 20px" }
                }
              >
                <img
                  style={{ backgroundColor: "transparent" }}
                  height={30}
                  width={120}
                  src={PaypalLogo}
                />
              </button>
            </div>

            {isCardPayment && (
              <CardFields
                errorStyle={errorStyle}
                formErrors={formErrors}
                labelStyle={labelStyle}
                setBillingAddress={setBillingAddress}
                billingAddress={billingAddress}
                setFormErrors={setFormErrors}
              />
            )}
            <p style={errorStyle}>{paymentError}</p>

            <SubmitPayment
              isCardPayment={isCardPayment}
              setServerError={setServerError}
              setFailureMessage={setFailureMessage}
              setPaymentError={setPaymentError}
              userToken={userToken}
              billingAddress={billingAddress}
              setFormErrors={setFormErrors}
              setIsSubmitted={setIsSubmitted}
              setMessage={setMessage}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <BsLockFill
                style={{
                  width: "60px",
                  height: "40px",
                  backgroundColor: "#2c2c2c",
                }}
              />
              <p style={{ backgroundColor: "#2c2c2c" }}>
                We take data and privacy seriously. Your payment information is
                secure and will be used only to process your gift.
              </p>
            </div>
          </div>
        )}
        {isSubmitted && (
          <Message
            serverError={serverError}
            failureMessage={failureMessage}
            amount={billingAddress.amount}
            organization={user}
            log={message}
          />
        )}
      </div>
    </Modal>
  );
};

export default BuyDM;
