import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import AmericanExpressLogo from "../../assets/americanExpress.svg";
import DiscoverLogo from "../../assets/discover.svg";
import MasterCardLogo from "../../assets/mastercard.svg";
import VisaLogo from "../../assets/visa.svg";
import PaypalLogo from "../../assets/paypal.png";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BarLoader } from "react-spinners";
import BuyGiftCardForm from "../Form/BuyGiftCard";
import GiftCardCardFields from "../Form/GiftCardCardFields";
import OrganizationFields from "../Form/OrganizationFields";
import BuyTransactionModal from "../modals/BuyTransactionModal";

const labelStyle = {
  background: "rgb(212, 245, 211)",
  color: "black",
  padding: "8px",
  display: "block",
  marginBottom: "4px",
  borderRadius: "4px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ddd",
  width: "95%",
  marginBottom: "16px",
};

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginTop: "2px",
  background: "rgb(212, 245, 211)",
  fontWeight: "bold",
};

function validateForm(billingAddress, isCardPayment, isOrganizationSelected) {
  const errors = {};

  if (!isOrganizationSelected && !billingAddress.org.name.trim()) {
    errors.orgName = "Organization name is required.";
  }
  if (!isOrganizationSelected && !billingAddress.org.email.trim()) {
    errors.orgEmail = "Organization Email is required.";
  }

  if (isOrganizationSelected && !billingAddress.orgId.trim()) {
    errors.orgId = "Organization is required.";
  }
  if (!billingAddress.email || !/\S+@\S+\.\S+/.test(billingAddress.email)) {
    errors.email = "Valid email is required.";
  }
  if (!billingAddress.firstName.trim()) {
    errors.firstName = "First name is required.";
  }
  if (!billingAddress.lastName.trim()) {
    errors.lastName = "Last name is required.";
  }
  if (!billingAddress.country.trim()) {
    errors.country = "Country is required.";
  }
  if (!billingAddress.addressLine1.trim()) {
    errors.addressLine1 = "Address is required.";
  }
  if (!billingAddress.countryCode.trim()) {
    errors.countryCode = "Country code is required.";
  } else if (!/^\+\d+$/.test(billingAddress.countryCode)) {
    errors.countryCode =
      "Country code must start with '+' followed by numbers.";
  }
  if (!billingAddress.postalCode.trim()) {
    errors.postalCode = "Postal code is required.";
  } else if (!/^\d+$/.test(billingAddress.postalCode)) {
    errors.postalCode = "Postal code must be numeric.";
  }
  if (
    billingAddress.phoneNumber.trim() &&
    !/^\+?\d{10,15}$/.test(billingAddress.phoneNumber)
  ) {
    errors.phoneNumber = "Phone number must be 10-15 digits.";
  }
  if (!billingAddress.amount || parseFloat(billingAddress.amount) <= 0) {
    errors.amount = "Please select gift card!";
  }

  // Card validations
  if (!billingAddress.card.name.trim() && isCardPayment) {
    errors.cardName = "Cardholder name is required.";
  }
  if (!billingAddress.card.no.trim() && isCardPayment) {
    errors.cardNo = "Card number is required.";
  } else if (!/^\d{16}$/.test(billingAddress.card.no) && isCardPayment) {
    errors.cardNo = "Card number must be 16 digits.";
  }
  if (!billingAddress.card.cvv.trim() && isCardPayment) {
    errors.cardCvv = "CVV is required.";
  } else if (!/^\d{3}$/.test(billingAddress.card.cvv) && isCardPayment) {
    errors.cardCvv = "CVV must be 3 digits.";
  }
  if (!billingAddress.card.expiry.trim() && isCardPayment) {
    errors.cardExpiry = "Expiry date is required.";
  } else if (
    !/^\d{4}-(0[1-9]|1[0-2])$/.test(billingAddress.card.expiry) &&
    isCardPayment
  ) {
    errors.cardExpiry = "Expiry date must be in YYYY-MM format.";
  } else {
    const [year, month] = billingAddress.card.expiry.split("-").map(Number);
    const currentDate = new Date();
    const expiryDate = new Date(year, month - 1); // Month is 0-based in Date.

    if (expiryDate < currentDate && isCardPayment) {
      errors.cardExpiry = "Expiry date must be in the future.";
    }
  }

  return errors;
}

const Message = ({
  log,
  amount,
  serverError,
  failureMessage,
}) => {
  return (
    <div style={{ borderRadius:"12px" ,background: "rgb(212, 245, 211)", textAlign: "center", width: "350px", padding: "20px 0px" }}>
      {log?.transactionStatus === "success" ? (
        <h3 style={labelStyle}>Successful Transaction of {amount}$!</h3>
      ) : (
        <h3 style={labelStyle}>Transaction of {amount}$ failed!</h3>
      )}

      {failureMessage !== null && (
        <p style={{ ...labelStyle, marginTop: "12px" }}>
          <span style={{ fontWeight: "bold", backgroundColor: "transparent", color:"black" }}>
            Failure Reason :
          </span>{" "}
          {failureMessage}
        </p>
      )}

      {log?.transactionStatus === "success" && (
        <>
          <p style={{ ...labelStyle, marginTop: "12px" }}>
            <span style={{ fontWeight: "bold", backgroundColor: "#2c2c2c" }}>
              {(log.amount * 0.8).toFixed(2)} DM
            </span>{" "}
            donated to {log.recipientUserId.businessName}
          </p>
          <p style={labelStyle}>
            <span style={{ fontWeight: "bold", backgroundColor: "#2c2c2c" }}>
              {(log.amount * 0.2).toFixed(2)} DM
            </span>{" "}
            credited to your wallet
          </p>
        </>
      )}
      <button
        style={{ marginTop: "15px", fontWeight: "bold", width: "150px" }}
        onClick={() => window.location.reload()}
      >
        Close
      </button>
    </div>
  );
};
const SubmitPayment = ({
  billingAddress,
  setFormErrors,
  setIsSubmitted,
  setMessage,
  setFailureMessage,
  setServerError,
  isCardPayment,
  isOrganizationSelected,
}) => {
  const [isPaying, setIsPaying] = useState(false);

  const handleClick = async () => {
    setIsPaying(true);
    const errors = validateForm(
      billingAddress,
      isCardPayment,
      isOrganizationSelected
    );

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsPaying(false);
      return;
    }
    setFormErrors({});

    try {
      let data = isCardPayment
        ? JSON.stringify({
            amount: billingAddress.amount,
            phoneNo: billingAddress.phoneNumber,
            firstName: billingAddress.firstName,
            lastName: billingAddress.lastName,
            country: billingAddress.country,
            countryCode: billingAddress.countryCode,
            postalCode: billingAddress.postalCode,
            orgId: billingAddress.orgId,
            address: billingAddress.addressLine1,
            email: billingAddress.email,
            card: {
              number: billingAddress.card.no,
              expiry: billingAddress.card.expiry,
              cvv: billingAddress.card.cvv,
              name: billingAddress.card.name,
            },
            organizationName: billingAddress.org.name,
            organizationEmail: billingAddress.org.email,
            organizationWebsite: billingAddress.org.website,
          })
        : JSON.stringify({
            amount: billingAddress.amount,
            phoneNo: billingAddress.phoneNumber,
            firstName: billingAddress.firstName,
            lastName: billingAddress.lastName,
            country: billingAddress.country,
            countryCode: billingAddress.countryCode,
            postalCode: billingAddress.postalCode,
            orgId: billingAddress.orgId,
            address: billingAddress.addressLine1,
            email: billingAddress.email,
            return_url: `${window.location.href}?status=success`,
            cancel_url: `${window.location.href}?status=failed`,
            organizationName: billingAddress.org.name,
            organizationEmail: billingAddress.org.email,
            organizationWebsite: billingAddress.org.website,
          });

      const response = await fetch(
        isCardPayment
          ? `${process.env.REACT_APP_LOCAL_API}/card-payment`
          : `${process.env.REACT_APP_LOCAL_API}/paypal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        }
      );

      const orderData = await response.json();

      if (isCardPayment) {
        setIsSubmitted(true);

        if (orderData.isSuccessful) {
          setMessage(orderData.log);
        } else if (!orderData.isSuccessful) {
          setMessage(orderData.log);
          setFailureMessage(orderData.message);
        }
      } else if (response.status === 401) {
        console.log(orderData.error);
        setFormErrors(orderData.error);
      } else if (response.status === 302) {
        window.location.replace(orderData.url);
      } else {
        setFailureMessage("Internal Server Error");
        setServerError(true);
      }
      setIsPaying(false);
    } catch (error) {
      console.log(error);

      setFailureMessage("Internal Server Error");
      setServerError(true);
    }
  };

  return !isPaying ? (
    <button
      className={isPaying ? "btn" : "btn btn-primary"}
      style={{ width: "100%" }}
      onClick={handleClick}
    >
      DONATE
    </button>
  ) : (
    <BarLoader color="#3ca151" />
  );
};

function GiftCard() {
  let [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const [userInfo, setUserInfo] = useState(null);
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const [isEmailVerified, setIsEmailVerified] = useState(
    userToken !== null ? true : false
  );
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [exchangeValue, setExchangeValue] = useState([]);
  const [communityInfo, setCommunityInfo] = useState([]);
  const [failureMessage, setFailureMessage] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [isOrganizationSelected, setIsOrganizationSelected] = useState(false);

  const loadCommunityData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/community`,
    };

    axios
      .request(config)
      .then((response) => {
        setCommunityInfo(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LOCAL_API}/currency`)
      .then((response) => {
        setExchangeValue(response.data[0].value);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    loadCommunityData();
  }, []);

  const [billingAddress, setBillingAddress] = useState({
    amount: "",
    orgId: "",
    email: "",
    firstName: "",
    lastName: "",
    country: "",
    addressLine1: "",
    countryCode: "",
    postalCode: "",
    phoneNumber: "",
    card: {
      name: "",
      no: "",
      cvv: "",
      expiry: "",
    },
    org: {
      name: "",
      email: "",
      website: "",
    },
  });

  const loadUserData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setUserInfo(response.data.data);
        setBillingAddress((prev) => ({
          ...prev,
          email: response.data.data.email,
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          country: response.data.data.country || "",
          countryCode: response.data.data.countryCode
            ? response.data.data.countryCode.toString()
            : "",
          addressLine1: response.data.data.address || "",
          postalCode: response.data.data.postalCode
            ? response.data.data.postalCode.toString()
            : "",
          phoneNumber: response.data.data.phoneNo
            ? response.data.data.phoneNo.toString()
            : "",
        }));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (userToken) {
      loadUserData();
    }
  }, []);

  const [paymentError, setPaymentError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState(null);
  const [isCardPayment, setIsCardPayment] = useState(true);

  return (
    <div className="gift-card-container w-screen">
      <Navbar isOpen={true} />
      <div className="flex flex-col width-full">
        <div className="flex flex-col gift-card-heading-cont">
          <h1>
            Buy a Donor Money
            <br /> Gift Card for Your Favorite
            <br /> Nonprofit
          </h1>
          <p>Innovative Way of Giving</p>
        </div>
        {!isSubmitted && (
          <div className="gift-card-form">
            <h1 style={{ background: "rgb(212, 245, 211)", color: "black" }}>
              Buy Now
            </h1>
            <p
              style={{
                backgroundColor: "rgb(212, 245, 211)",
                color: "black",
                margin: 0,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              100% of Donor Money Gift Card sales go to nonprofits and impact
              enterprises
            </p>

            <div className="gift-form-main" style={{ width: "100%" }}>
              <h2
                style={{
                  background: "rgb(212, 245, 211)",
                  color: "black",
                  margin: "0",
                  marginTop: "12px",
                  fontWeight: "bold",
                  padding: "10px 0px",
                }}
              >
                Select Organization
              </h2>
              <select
                className="gift-card-input"
                style={inputStyle}
                type="number"
                name="amount"
                value={billingAddress.orgId}
                onChange={(e) => {
                  setBillingAddress((prev) => ({
                    ...prev,
                    orgId: e.target.value,
                  }));
                  setIsOrganizationSelected(true);
                }}
              >
                <option value={""}>Select Organisation</option>
                {communityInfo.map((user, index) => (
                  <option value={user._id} key={index}>
                    {user?.businessName}
                  </option>
                ))}
              </select>
              {formErrors.orgId && (
                <span style={errorStyle}>{formErrors.orgId}</span>
              )}

              <div style={{ backgroundColor: "transparent" }}>
                <button
                  onClick={() => {
                    setIsOrganizationSelected(!isOrganizationSelected);
                    setBillingAddress((prev) => ({
                      ...prev,
                      orgId: "",
                      org: {
                        name: "",
                        email: "",
                        website: "",
                      },
                    }));
                  }}
                >
                  Other
                </button>
              </div>

              {!isOrganizationSelected && (
                <OrganizationFields
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  billingAddress={billingAddress}
                  setBillingAddress={setBillingAddress}
                  labelStyle={labelStyle}
                  errorStyle={errorStyle}
                  inputStyle={inputStyle}
                />
              )}

              <BuyGiftCardForm
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                formErrors={formErrors}
                exchangeValue={exchangeValue}
                userToken={userToken}
                setFormErrors={setFormErrors}
                setIsEmailVerified={setIsEmailVerified}
                isEmailVerified={isEmailVerified}
              />
              <h2 style={labelStyle}>Payment Information</h2>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  backgroundColor: "transparent",
                }}
              >
                <button
                  onClick={() => setIsCardPayment(true)}
                  style={
                    isCardPayment
                      ? { backgroundColor: "black" }
                      : { backgroundColor: "#1E201E" }
                  }
                >
                  <img
                    style={{ backgroundColor: "transparent" }}
                    height={30}
                    width={40}
                    src={MasterCardLogo}
                  />
                  <img
                    style={{ backgroundColor: "transparent" }}
                    height={30}
                    width={40}
                    src={VisaLogo}
                  />
                  <img
                    style={{ backgroundColor: "transparent" }}
                    height={30}
                    width={40}
                    src={DiscoverLogo}
                  />
                  <img
                    style={{ backgroundColor: "transparent" }}
                    height={30}
                    width={40}
                    src={AmericanExpressLogo}
                  />
                </button>
                <button
                  onClick={() => setIsCardPayment(false)}
                  style={
                    !isCardPayment
                      ? { backgroundColor: "black", padding: "10px 20px" }
                      : { backgroundColor: "#1E201E", padding: "10px 20px" }
                  }
                >
                  <img
                    style={{ backgroundColor: "transparent" }}
                    height={30}
                    width={120}
                    src={PaypalLogo}
                  />
                </button>
              </div>

              {isCardPayment && (
                <GiftCardCardFields
                  inputStyle={inputStyle}
                  billingAddress={billingAddress}
                  labelStyle={labelStyle}
                  errorStyle={errorStyle}
                  setBillingAddress={setBillingAddress}
                  setFormErrors={setFormErrors}
                  formErrors={formErrors}
                />
              )}
              <p style={errorStyle}>{paymentError}</p>

              <SubmitPayment
                isOrganizationSelected={isOrganizationSelected}
                isCardPayment={isCardPayment}
                setServerError={setServerError}
                setFailureMessage={setFailureMessage}
                setPaymentError={setPaymentError}
                billingAddress={billingAddress}
                setFormErrors={setFormErrors}
                setIsSubmitted={setIsSubmitted}
                setMessage={setMessage}
              />
            </div>
            {id && token && (status === "success" || status === "failed") && (
              <BuyTransactionModal
                setSearchParams={setSearchParams}
                status={status}
                id={id}
                token={token}
              />
            )}
          </div>
        )}
        {isSubmitted && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Message
              serverError={serverError}
              failureMessage={failureMessage}
              amount={billingAddress.amount}
              log={message}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default GiftCard;
