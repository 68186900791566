import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../common/Footer";
import SendcoinsOpen from "./SendcoinsOpen";
import EditUserModal from "../modals/EditUser";
import OrgLogo from "../../assets/Organization.png";
import IndLogo from "../../assets/Individual.png";
import AdmLogo from "../../assets/Admin.png";
import BuyDM from "../modals/BuyDM";
import BuyTransactionModal from "../modals/BuyTransactionModal";

function ProfileView() {
  let [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const [user, setUser] = useState({});
  const { userName } = useParams();
  const [profile, setProfile] = useState("");
  const [cuser, setCuser] = useState({});
  const userToken = localStorage.getItem("userToken");
  const [showEdit, setShowEdit] = useState(false);
  const openModal = (user) => {
    setShowEdit(true);
  };
  const [selected, setSelected] = useState({ businessName: "", _id: "" });
  const [showModal, setShowModal] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LOCAL_API}/currency`)
      .then((response) => {
        setCurrencyData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const closeModal = () => {
    setShowEdit(false);
  };

  const handleViewProfile = () => {
    setShowModal(true);
  };

  const handleBuyModal = () => {
    setShowBuyModal(true);
  };

  const loadUser = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/viewuser/${userName}`,
    };

    axios
      .request(config)
      .then((response) => {
        setUser(response?.data?.data);
        setSelectedUser(response?.data?.data);
        switch (response?.data?.data.profileType) {
          case "individual":
            setProfile("Individual");
            break;

          case "ngo":
            setProfile("NGO");
            break;

          case "business":
            setProfile("Business");
            break;

          case "impactOrg":
            setProfile("Impact Organisation");
            break;

          default:
            break;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadCurrentUser = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCuser(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadUser();
    loadCurrentUser();
  }, []);

  return (
    <>
      <div className="pcontainer">
        <div className="profile-content">
          <div className="profile-info">
            <img
              className="circular-image"
              src={
                user?.profilePic ||
                (user?.profileType === "admin"
                  ? AdmLogo
                  : user?.profileType === "individual"
                  ? IndLogo
                  : OrgLogo)
              }
              alt="profile"
              style={{ height: "60px", margin: "5px" }}
            />
            <EditUserModal
              userId={user?._id}
              userToken={userToken}
              onClose={closeModal}
              showEdit={showEdit}
              isAdmin={false}
            />
            {(user?.profileType === "impactOrg" ||
              user?.profileType === "business" ||
              user?.profileType === "ngo") && <h2>{user?.businessName}</h2>}
            {(user?.profileType === "individual" ||
              user?.profileType === "admin") && (
              <h2>
                {user?.firstName} {user?.lastName}
              </h2>
            )}

            <p>{profile}</p>
            {user._id === cuser._id && (
              <button
                style={{ marginLeft: "10px", fontSize: "14px" }}
                onClick={openModal}
              >
                Edit Profile
              </button>
            )}
            <div className="profile-about">
              {user?.about && <h3>About</h3>}
              <p>{user?.about} </p>
              <h3>Contact Information</h3>

              {(user?.profileType === "business" ||
                user?.profileType === "ngo" ||
                user?.profileType === "impactOrg") && (
                <p>
                  <strong>Contact Name:</strong>{" "}
                  {`${user?.firstName} ${user?.lastName}`}
                </p>
              )}
              <p>
                <strong>Country:</strong> {user?.country}
              </p>
              {user?.website && (
                <p>
                  <strong>Website:</strong>
                  <a href={user?.website}>{user?.website}</a>
                </p>
              )}

              <div style={{display:"flex", gap:"4px"}}>
                <p
                  style={{ color: "#90e172", margin: "0px", fontWeight: "700" }}
                >
                  Balance :
                </p>
                <p style={{ margin: "0px", fontWeight: "700" }}>
                  {user?.wallet?.walletPoint.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  DM
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "200px",
                  marginTop: "auto",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleViewProfile();
                }}
              >
                Donate DM
              </button>
              <button
                id="blue-btn"
                style={{
                  width: "200px",
                  marginTop: "auto",
                  marginBottom: "10px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleBuyModal();
                }}
              >
                Buy DM
              </button>
            </div>
          </div>

          {id && token && (status === "success" || status === "fail") && (
            <BuyTransactionModal
              setSearchParams={setSearchParams}
              status={status}
              id={id}
              token={token}
            />
          )}
          {selectedUser !== null && currencyData && (
            <BuyDM
              exchangeValue={currencyData[0].value}
              user={selectedUser}
              isModalOpen={showBuyModal}
              setIsModalOpen={setShowBuyModal}
            />
          )}
          <SendcoinsOpen
            showModal={showModal}
            closeModal={() => setShowModal(false)}
            user={selectedUser}
          />
          <div className="featured-blogs">
            <SendcoinsOpen user={user} />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ProfileView;
