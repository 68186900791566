import React from "react";

function validateOrgField(field, value) {
  switch (field) {
    case "org.name":
      if (!value.trim()) {
        return "Organization name is required.";
      }
      break;

    case "org.email":
      if (!value.trim()) {
        return "Organization email is required.";
      } else if (
        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
      ) {
        return "Invalid email format.";
      }
      break;

    default:
      return null;
  }
  return null;
}

function OrganizationFields({
  setBillingAddress,
  billingAddress,
  labelStyle,
  errorStyle,
  formErrors,
  setFormErrors,
  inputStyle,
}) {
  const handleOrgChange = (e) => {
    const { name, value } = e.target;
    const fieldKey = `org.${name}`; // Construct the field name for validation

    // Validate the field and get an error message if any
    const error = validateOrgField(fieldKey, value);

    // Update the billing address state
    setBillingAddress((prevState) => ({
      ...prevState,
      org: {
        ...prevState.org,
        [name]: value,
      },
    }));

    // Update the form errors state
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [`org${name.charAt(0).toUpperCase() + name.slice(1)}`]: error,
    }));
  };

  return (
    <>
      <label style={labelStyle}>Organization Name:</label>
      <input
        className="gift-card-input"
        style={inputStyle}
        type="text"
        name="name"
        value={billingAddress.org.name}
        onChange={handleOrgChange}
        placeholder="Enter organization name"
      />
      {formErrors.orgName && (
        <span style={errorStyle}>{formErrors.orgName}</span>
      )}

      {/* Organization Email */}
      <label style={labelStyle}>Organization Email:</label>
      <input
        className="gift-card-input"
        style={inputStyle}
        type="text"
        name="email"
        value={billingAddress.org.email}
        onChange={handleOrgChange}
        placeholder="Enter organization email"
      />
      {formErrors.orgEmail && (
        <span style={errorStyle}>{formErrors.orgEmail}</span>
      )}

      {/* Organization Website */}
      <label style={labelStyle}>Organization Website:</label>
      <input
        className="gift-card-input"
        style={inputStyle}
        type="text"
        name="website"
        value={billingAddress.org.website}
        onChange={handleOrgChange}
        placeholder="Enter organization website"
      />
    </>
  );
}

export default OrganizationFields;