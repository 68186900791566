import React, { useState } from "react";
import BulkRegistration from "./BulkRegistration";
import Demographics from "./Demographics";
import EmailEditor from "./EmailEditor";
import AdminDisputesPage from "../dispute/AdminDispute";
import AdminFeedback from "../feedback/AdminFeedback";
import AdminHome from "./AdminHome";
import UnverifiedUsers from "./UnverifiedUsers";
import NewDM from "./25DM.js"
import SevenDays from "./SevenDaysUsers";
import "./style.css";


const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "bulk":
        return <BulkRegistration />;
      case "graphs":
        return <Demographics />;
      case "email":
        return <EmailEditor />;
      case "home":
        return <AdminHome />;
      case "feedback":
        return <AdminFeedback />;
      case "dispute":
        return <AdminDisputesPage />;
      case "unverifiedUsers":
        return <UnverifiedUsers />;
        case "SevenDays":
          return <SevenDays />;
          case "NewDM":
            return <NewDM />;
      default:
        return <AdminHome />;
    }
  };

  return (
    <>
      <div className="admin-dashboard">
        <button className="menu-toggle" onClick={toggleSidebar}>
          Menu
        </button>
        <nav className={`side-bar ${isSidebarOpen ? "show" : ""}`}>
          <button
            className={`nav-link ${activeTab === "home" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("home");
              setSidebarOpen(false);
            }}
          >
            Home
          </button>
          <button
            className={`nav-link ${activeTab === "bulk" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("bulk");
              setSidebarOpen(false);
            }}
          >
            Bulk Registration
          </button>
          <button
            className={`nav-link ${activeTab === "graphs" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("graphs");
              setSidebarOpen(false);
            }}
          >
            Demographics
          </button>
          <button
            className={`nav-link ${activeTab === "email" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("email");
              setSidebarOpen(false);
            }}
          >
            Email Editor
          </button>
          <button
            className={`nav-link ${activeTab === "feedback" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("feedback");
              setSidebarOpen(false);
            }}
          >
            Feedbacks
          </button>
          <button
            className={`nav-link ${activeTab === "dispute" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("dispute");
              setSidebarOpen(false);
            }}
          >
            Support Tickets
          </button>
          <button
            className={`nav-link ${
              activeTab === "unverifiedUsers" ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab("unverifiedUsers");
              setSidebarOpen(false);
            }}
          >
            Unverified Users
          </button>
          <button
            className={`nav-link ${
              activeTab === "SevenDays" ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab("SevenDays");
              setSidebarOpen(false);
            }}
          >
           New Accounts <span id="small">(Within 7 Days)</span>
          </button>
          <button
            className={`nav-link ${
              activeTab === "NewDM" ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab("NewDM");
              setSidebarOpen(false);
            }}
          >
           New Accounts  - <span id="small">(Who received 25DM)</span>
          </button>
        </nav>
        <div className="content">{renderContent()}</div>
      </div>
    </>
  );
};

export default AdminDashboard;
