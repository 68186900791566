import React from "react";

function validateField(field, value) {
  switch (field) {
    case "card.name":
      if (!value.trim()) {
        return "Cardholder name is required.";
      }
      break;

    case "card.no":
      if (!value.trim()) {
        return "Card number is required.";
      } else if (!/^\d{16}$/.test(value)) {
        return "Card number must be 16 digits.";
      }
      break;

    case "card.cvv":
      if (!value.trim()) {
        return "CVV is required.";
      } else if (!/^\d{3}$/.test(value)) {
        return "CVV must be 3 digits.";
      }
      break;

    case "card.expiry":
      if (!value.trim()) {
        return "Expiry date is required.";
      } else if (!/^\d{4}-(0[1-9]|1[0-2])$/.test(value)) {
        return "Expiry date must be in YYYY-MM format.";
      } else {
        const [year, month] = value.split("-").map(Number);
        const currentDate = new Date();
        const expiryDate = new Date(year, month - 1);

        if (expiryDate < currentDate) {
          return "Expiry date must be in the future.";
        }
      }
      break;

    default:
      return null;
  }
  return null;
}

function CardFields({
  setBillingAddress,
  billingAddress,
  labelStyle,
  errorStyle,
  formErrors,
  setFormErrors,
}) {
  const handleCardChange = (e) => {
    const { name, value } = e.target;
    const fieldKey = `card.${name}`; // Construct the field name for validation

    // Validate the field and get an error message if any
    const error = validateField(fieldKey, value);

    // Update the billing address state
    setBillingAddress((prevState) => ({
      ...prevState,
      card: {
        ...prevState.card,
        [name]: value,
      },
    }));

    // Update the form errors state
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [`card${name.charAt(0).toUpperCase() + name.slice(1)}`]: error,
    }));
  };

  return (
    <>
      <div>
        <label style={labelStyle}>Cardholder Name:</label>
        <input
          type="text"
          name="name"
          value={billingAddress.card.name}
          onChange={handleCardChange}
          placeholder="Enter cardholder name"
        />
        {formErrors.cardName && (
          <span style={errorStyle}>{formErrors.cardName}</span>
        )}
      </div>

      {/* Card Number */}
      <div>
        <label style={labelStyle}>Card Number:</label>
        <input
          type="text"
          name="no"
          value={billingAddress.card.no}
          onChange={handleCardChange}
          placeholder="Enter card number"
        />
        {formErrors.cardNo && (
          <span style={errorStyle}>{formErrors.cardNo}</span>
        )}
      </div>

      {/* CVV */}
      <div>
        <label style={labelStyle}>CVV:</label>
        <input
          type="text"
          name="cvv"
          value={billingAddress.card.cvv}
          onChange={handleCardChange}
          placeholder="Enter CVV"
        />
        {formErrors.cardCvv && (
          <span style={errorStyle}>{formErrors.cardCvv}</span>
        )}
      </div>

      {/* Expiry */}
      <div>
        <label style={labelStyle}>Expiry Date:</label>
        <input
          type="text"
          name="expiry"
          value={billingAddress.card.expiry}
          onChange={handleCardChange}
          placeholder="YYYY-MM"
        />
        {formErrors.cardExpiry && (
          <span style={errorStyle}>{formErrors.cardExpiry}</span>
        )}
      </div>
    </>
  );
}

export default CardFields;
