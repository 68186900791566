import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import CreateUser from "../modals/CreateUser";
import Footer from "../common/Footer";

const AdminHome = () => {
  const userToken = localStorage.getItem("userToken");
  const [ngos, setNgos] = useState([]);
  const [filter, setfilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [transType, setTransType] = useState("");
  const [userData, setUserData] = useState({});
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [expiry, setExpiry] = useState(0);
  const [currencyVal, setCurrencyVal] = useState(0);
  const [exChangeRate, setExChangeRate] = useState(0);
  const [startDateVal, setStartDateVal] = useState({
    value: moment().format("YYYY-MM-DD"),
  });

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ngos?.slice(indexOfFirstItem, indexOfLastItem);

  const loadExpire = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/expiry`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        let totalAmount = 0;
        response?.data?.upcomingExpiries?.forEach((transaction) => {
          totalAmount += transaction.amount || 0;
        });
        setExpiry(totalAmount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/admindashboard?profileType=${filter}&searchTerm=${searchTerm}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setNgos(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadUserData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/dashboard`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {});
  };

  const loadCoin = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/currency`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setExChangeRate(response.data[0]?.value?.toFixed(2));
        setCurrencyVal(response.data[0]?.value);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadCoin();
    loadData();
    loadUserData();
    loadExpire();
  }, [filter, searchTerm]);

  const handleCoin = () => {
    let data = JSON.stringify({
      currency: "usd",
      value: exChangeRate,
      startDate: startDateVal?.value,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/currency`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        window.location.reload();
        Swal.fire({
          title: "Success!",
          confirmButtonColor: "#3ca151",
          text: "Coin Value Changed Successfully!",
          icon: "success",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          confirmButtonColor: "#3ca151",
          text: "Something Went Wrong!",
          icon: "error",
        });
      });
  };

  const downloadData = async (endpoint, name) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API}${endpoint}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);

      link.click();
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    downloadData(
      `/report?startDate=${sDate}&endDate=${eDate}&transactionType=${transType}`,
      "Transaction_Report.csv"
    );
    downloadData(`/userreport`, "User_List.csv");
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const validateOrg = (user) => {
    let data = JSON.stringify({ isVerified: !user.isVerified });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user/${user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          title: "Success!",
          text: "User Updated Successfully!",
          icon: "success",
          confirmButtonColor: "#3ca151",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          confirmButtonColor: "#3ca151",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  return (
    <>
      <div style={{ margin: "100px auto" }}>
        <h1 id="head_dash" style={{ margin: "30px 150px" }}>
          Admin Dashboard
        </h1>
        <div
          id="adminboxwrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div className="shadow-box box-width-correct">
            <h3>
              {userData?.user?.firstName} {userData?.user?.lastName}
              <br />
              <span id="today">
                ({userData?.user?.profileType.toUpperCase()})
              </span>
              <br />
              Referral Code: {userData?.user?.referCode}
            </h3>
            <h3>
              Balance: ${" "}
              {(userData?.wallet?.walletPoint * currencyVal).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              <span id="today">
                (1 DM = ${currencyVal?.toFixed(2)}){" "}
                {
                  //<a id="today" href='/currency'>Price History</a>
                }
              </span>
            </h3>
            <h3>
              No. of DM:{" "}
              {userData?.wallet?.walletPoint?.toLocaleString(undefined, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}
            </h3>
            {expiry <= 0 && <h5>No automatic transfers in the next 30 days</h5>}
            {expiry > 0 && (
              <h5>
                Note : Your{" "}
                {expiry.toLocaleString(undefined, {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}{" "}
                DM will auto transfer in 10 Days
              </h5>
            )}
          </div>

          <div className="shadow-box box-width-correct">
            <h2>Reports</h2>
            <form onSubmit={handleDownload}>
              <label htmlFor="sDate">Start Date:</label>
              <input
                type="date"
                id="sDate"
                name="sDate"
                value={sDate}
                onChange={(e) => setSDate(e.target.value)}
              />
              <label htmlFor="eDate">End Date:</label>
              <input
                type="date"
                id="eDate"
                name="eDate"
                value={eDate}
                onChange={(e) => setEDate(e.target.value)}
              />
              <select onChange={(e) => setTransType(e.target.value)}>
                <option value="">Type</option>
                <option value="Donation">Donation</option>
                <option value="Grant">Grant</option>
                <option value="Sale">Sale</option>
                <option value="Purchase">Purchase</option>
                <option value="Barter">Barter</option>
                <option value="Gift">Gift</option>

                {/* Add more options as needed */}
              </select>

              <button type="submit">Download Report</button>
            </form>{" "}
          </div>

          <div className="shadow-box box-width-correct">
            <h3>Exchange Rate</h3>

            <input
              name="currencyValue"
              value={exChangeRate}
              type="number"
              onChange={(e) => {
                setExChangeRate(e.target.value);
              }}
            />
            <input
              name="currencyDate"
              value={startDateVal?.value}
              type="date"
              min={getCurrentDate()}
              onChange={(e) => setStartDateVal({ value: e.target.value })}
            />
            <button type="button" onClick={handleCoin}>
              Change
            </button>
          </div>
        </div>

        <div className="shadow-box" style={{ width: "74%", margin: "auto" }}>
          <h3>Create New User!</h3>
          <CreateUser userId={userData?.user?._id} ngo={false} />
        </div>

        <div
          className="shadow-box admin-box"
          style={{ width: "74%", marginTop: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3>All Users</h3>
            <div>
              <input
                placeholder="Search user by name"
                style={{ marginRight: "5px", padding: "10px" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select onChange={(e) => setfilter(e.target.value)}>
                <option value="">All</option>
                <option value="admin">Admin</option>
                <option value="ngo">NGO</option>
                <option value="impactOrg">Impact Organisation</option>
                <option value="individual">Individual</option>
                <option value="business">Business</option>
              </select>
            </div>
          </div>
          <hr style={{ marginBottom: "20px" }} />
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Available DM</th>
                  <th>DM Sent</th>
                  <th>DM Recieved</th>
                  <th>Status*</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((ngo, index) => (
                  <tr key={index}>
                    <td>
                      <a href={`/admin-user/${ngo.user?._id}`}>
                        {ngo.user.profileType === "ngo" ||
                        ngo.user.profileType === "impactOrg" ||
                        ngo.user.profileType === "business"
                          ? ngo.user.businessName
                          : ngo.user?.firstName + " " + ngo.user?.lastName}
                      </a>
                    </td>
                    <td>
                      {ngo.wallet?.walletPoint?.toLocaleString(undefined, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      }) || "0"}
                    </td>
                    <td>
                      {ngo?.totalSent?.toLocaleString(undefined, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      }) || "0"}
                    </td>
                    <td>
                      {ngo?.totalReceived?.toLocaleString(undefined, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      }) || 0}
                    </td>
                    {ngo?.user?.profileType !== "admin" &&
                      ngo?.user?.profileType !==
                        "individual" &&(
                          <td>
                            {ngo?.user?.isVerified
                              ? "Verified"
                              : "Not Verified"}
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                validateOrg(ngo?.user);
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {ngo?.user?.isVerified ? "Hide Profile" : "Validate"}
                            </button>
                          </td>
                        )}{" "}
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(ngos?.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminHome;
