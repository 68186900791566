import React, { useState, useEffect } from "react";
import axios from "axios";
import UserCard from "./UserCard";
import SendcoinsOpen from "./SendcoinsOpen";
import Banner from "../../assets/community.jpg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BuyDM from "../modals/BuyDM";
import { useSearchParams } from "react-router-dom";
import BuyTransactionModal from "../modals/BuyTransactionModal";

const Community = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState({ businessName: "", _id: "" });
  const [showModal, setShowModal] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(12);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    type: "",
    text: "",
  });
  const [selectedUser, setSelectedBuyModal] = useState(null);
  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LOCAL_API}/currency`)
      .then((response) => {
        setCurrencyData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    loadUsers();
  }, [filter]);

  const loadUsers = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/community?type=${filter.type}&searchTerm=${filter.text}`,
    };

    axios
      .request(config)
      .then((response) => {
        setUsers(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleViewProfile = (user) => {
    setSelected(user);
    setShowModal(true);
  };

  const handleBuyModal = (organization = null) => {
    setShowBuyModal(true);

    setSelectedBuyModal(organization);
  };

  // Logic for pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="">
      <img
        style={{ width: "100%", height: "420px", objectFit: "cover" }}
        src={Banner}
        alt="community"
      />
      <h4
        style={{
          fontWeight: "400",
          margin: "20px 30px",
          textAlign: "center",
        }}
      >
        Purchasing Donor Money directly from a Nonprofit or Impact Enterprise
        doubles your impact by creating a circular flow of funding that grows as
        the use and value of Donor Money increases. While we work to integrate
        payment gateways, please reach out directly to the following
        organizations with Donor Money balances to arrange purchase and payment
        directly from them.
      </h4>
      <div className="filterBoxCom">
        <input
          type="text"
          placeholder="Search Organisation by name"
          value={filter.text}
          onChange={(e) => setFilter({ ...filter, text: e.target.value })}
        />
        <select
          value={filter.type}
          onChange={(e) => setFilter({ ...filter, type: e.target.value })}
        >
          <option value=""> Select Type</option>
          <option value="impactOrg">Impact Organisation</option>
          <option value="ngo">NPO</option>
        </select>
      </div>
      <div
        id="community-container"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "20px",
              width: "100%",
              padding: "20px",
            }}
          >
            {Array.from({ length: 12 }).map((_, index) => (
              <div
                key={index}
                style={{
                  minWidth: "320px",
                  maxWidth: "320px",
                  flex: "1 1 150px",
                }}
              >
                <Skeleton
                  enableAnimation={true}
                  duration={1}
                  baseColor="#585757"
                  highlightColor="#bebebe"
                  height={150}
                />
                <Skeleton
                  enableAnimation={true}
                  duration={1}
                  baseColor="#585757"
                  highlightColor="#bebebe"
                  width="80%"
                  style={{ marginTop: 10 }}
                />
                <Skeleton
                  enableAnimation={true}
                  duration={1}
                  baseColor="#585757"
                  highlightColor="#bebebe"
                  width="60%"
                  style={{ marginTop: 10 }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>
            <div id="cards-container">
              {currentUsers.map((user) => (
                <div
                  style={{
                    cursor: "pointer",
                    WebkitTapHighlightColor: "transparent",
                  }}
                  onClick={() =>
                    (window.location.href = `/Community/${user.userName}`)
                  }
                  key={user._id}
                >
                  <UserCard
                    user={user}
                    onBuyModal={handleBuyModal}
                    onViewProfile={handleViewProfile}
                  />
                </div>
              ))}
            </div>
            {/* Pagination controls */}
            <nav style={{ display: "flex", justifyContent: "center" }}>
              <ul className="pagination">
                {Array.from(
                  { length: Math.ceil(users.length / usersPerPage) },
                  (_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        onClick={() => paginate(index + 1)}
                        className="page-link"
                      >
                        {index + 1}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </nav>
          </div>
        )}
        {id && token && (status === "success" || status === "failed") && (
          <BuyTransactionModal
            setSearchParams={setSearchParams}
            status={status}
            id={id}
            token={token}
          />
        )}
        {selectedUser !== null && currencyData && (
          <BuyDM
            exchangeValue={currencyData[0].value}
            user={selectedUser}
            isModalOpen={showBuyModal}
            setIsModalOpen={setShowBuyModal}
          />
        )}
        <SendcoinsOpen
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          user={selected}
        />
      </div>
    </div>
  );
};

export default Community;
