import React, { useState } from "react";
import Modal from "react-modal";
import OrgLogo from "../../assets/Organization.png"; // Update with the correct path
import axios from "axios";
import Swal from "sweetalert2";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function validateForm(billingAddress) {
  const errors = {};

  if (!billingAddress.email || !/\S+@\S+\.\S+/.test(billingAddress.email)) {
    errors.email = "Valid email is required.";
  }
  if (!billingAddress.firstName.trim()) {
    errors.firstName = "First name is required.";
  }
  if (!billingAddress.lastName.trim()) {
    errors.lastName = "Last name is required.";
  }
  if (!billingAddress.country.trim()) {
    errors.country = "Country is required.";
  }
  if (!billingAddress.addressLine1.trim()) {
    errors.addressLine1 = "Address is required.";
  }
  if (!billingAddress.countryCode.trim()) {
    errors.countryCode = "Country code is required.";
  } else if (!/^\+\d+$/.test(billingAddress.countryCode)) {
    errors.countryCode =
      "Country code must start with '+' followed by numbers.";
  }
  if (!billingAddress.postalCode.trim()) {
    errors.postalCode = "Postal code is required.";
  } else if (!/^\d+$/.test(billingAddress.postalCode)) {
    errors.postalCode = "Postal code must be numeric.";
  }
  if (
    billingAddress.phoneNumber.trim() &&
    !/^\+?\d{10,15}$/.test(billingAddress.phoneNumber)
  ) {
    errors.phoneNumber = "Phone number must be 10-15 digits.";
  }
  if (!billingAddress.amount || parseFloat(billingAddress.amount) <= 0) {
    errors.amount = "Amount must be greater than zero.";
  }

  return errors;
}

const labelStyle = {
  color: "#fff",
  padding: "8px",
  display: "block",
  marginBottom: "4px",
  borderRadius: "4px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ddd",
  width: "95%",
  marginBottom: "16px",
};

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginTop: "2px",
  backgroundColor: "#2c2c2c",
  fontWeight: "bold",
};

const GivingTuesdayGiftPage = () => {
  const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    countryCode: "+1",
    phoneNumber: "",
    addressLine1: "",
    postalCode: "",
    amount: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(billingAddress);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post("/api/donation", billingAddress); // Update with actual API endpoint
        Swal.fire("Success!", "Thank you for your generous gift!", "success");
        setBillingAddress({
          firstName: "",
          lastName: "",
          email: "",
          country: "",
          countryCode: "+1",
          phoneNumber: "",
          addressLine1: "",
          postalCode: "",
          amount: "",
        });
        navigate("/thank-you");
      } catch (error) {
        Swal.fire("Error", "Something went wrong. Please try again.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="giving-tuesday-container">
      <div className="logo-section">
        <img
          src={
            "https://www.givingtuesday.org/wp-content/uploads/2021/02/stacked-white.png"
          }
          alt="Organization Logo"
          style={{ maxWidth: "200px" }}
        />
      </div>
      <h1>YOUR GIVING TUESDAY GIFT WILL BE MATCHED </h1>
      <p>
        Join us this Giving Tuesday by making a generous contribution. Your gift
        can make a lasting impact!
      </p>

      <p>
        Children around the world are coping with extreme crises like severe
        weather, crushing poverty —and far too many children are living in war
        zones, from Gaza to Sudan and Ukraine to Afghanistan. 8 out of 10 food
        crises are driven by war.
      </p>

      <p>
        Save the Children is working around the clock to deliver lifesaving aid
        to children in the U.S. and around the world but we can't do it alone.
        Please, in honor of Giving Tuesday, will you give today while your gift
        can double in value?*
      </p>

      <ul>
        <li>
          $50 (becomes $100) - to help provide enough food to keep 10 children
          from going hungry for a month
        </li>
        <li>
          $150 (becomes $300)- to help provide warm, cozy blankets to 60
          children affected by conflict
        </li>
        <li>
          $300 (becomes $600)- to help provide psychosocial support to 30
          children
        </li>
      </ul>

      <h4>
        Don't miss this chance to have your Giving Tuesday gift go twice as far.
      </h4>

      <form onSubmit={handleSubmit} className="form-gift">
        {[
          { label: "First Name", name: "firstName", type: "text" },
          { label: "Last Name", name: "lastName", type: "text" },
          { label: "Email", name: "email", type: "email" },
          { label: "Country", name: "country", type: "text" },
          { label: "Country Code", name: "countryCode", type: "text" },
          { label: "Phone Number", name: "phoneNumber", type: "text" },
          { label: "Address", name: "addressLine1", type: "text" },
          { label: "Postal Code", name: "postalCode", type: "text" },
          { label: "Amount (USD)", name: "amount", type: "number" },
        ].map((field) => (
          <div key={field.name}>
            <label style={labelStyle}>{field.label}</label>
            <input
              style={inputStyle}
              name={field.name}
              type={field.type}
              value={billingAddress[field.name]}
              onChange={handleChange}
            />
            {errors[field.name] && (
              <span style={errorStyle}>{errors[field.name]}</span>
            )}
          </div>
        ))}
        <button
          type="submit"
          style={{
            backgroundColor: "#4caf50",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            width: "100%",
          }}
          disabled={loading}
        >
          {loading ? <BarLoader color="#fff" width={150} /> : "Donate"}
        </button>
        <p>
          We take data and privacy seriously. Your payment information is secure
          and will be used only to process your gift.
        </p>
      </form>
    </div>
  );
};

export default GivingTuesdayGiftPage;
