import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { BarLoader } from "react-spinners";

import axios from "axios";

export default function BuyTransactionModal({
  id,
  token,
  status,
  setSearchParams,
}) {
  const [open, setOpen] = useState(status === "success" || status === "failed");
  const [loader, setLoader] = useState(false);
  const [transactionDetails, setDetails] = useState(null);

  const loadTransactionDetails = () => {
    setLoader(true);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_LOCAL_API}/${id}/${token}/get-order?status=${status}`,
    };

    axios
      .request(config)
      .then((response) => {
        setDetails(response.data.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoader(false);
  };

  useEffect(() => {
    loadTransactionDetails();
  }, []);

  return (
    <Modal
      isOpen={open}
      contentLabel="Buy DM"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          color: "#fff",
          backgroundColor: "#2c2c2c",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "600px",
          margin: "50px auto",
          maxHeight: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
        },
      }}
    >
      <h1
        style={{
          backgroundColor: "#2c2c2c",
        }}
      >
        {status === "success" && "Transaction Successful!"}
        {status === "failed" && "Transaction Failed!"}
      </h1>

      {transactionDetails && (
        <>
          <p
            style={{
              backgroundColor: "#2c2c2c",
              margin: "6px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Transaction {status === "failed" ? "failed" : "successful"} for{" "}
            {transactionDetails.amount.toFixed(2)} DM
          </p>
          <p
            style={{
              backgroundColor: "#2c2c2c",
              margin: "6px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Amount :{" "}
            {(transactionDetails.amount * transactionDetails.currencyValue).toFixed(2)} $
          </p>
          <p
            style={{
              backgroundColor: "#2c2c2c",
              margin: "6px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Transaction Type : {transactionDetails.transactionType}
          </p>
          <p
            style={{
              backgroundColor: "#2c2c2c",
              margin: "6px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Payment Mode : {transactionDetails.paymentMode}
          </p>

          <button
            onClick={() => {
              setOpen(false);
              setSearchParams("");
              window.location.reload();
            }}
            style={{ margin: "20px 0px", padding: "10px 14px" }}
          >
            Close
          </button>
        </>
      )}
      {!transactionDetails && (
        <BarLoader height={"10px"} width={"200px"} color="#3ca151" />
      )}
    </Modal>
  );
}