import React, { useState } from "react";

const labelStyle = {
  backgroundColor: "#2c2c2c",
  color: "#fff",
  padding: "8px",
  display: "block",
  marginBottom: "4px",
  borderRadius: "4px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ddd",
  width: "95%",
  marginBottom: "16px",
};

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginTop: "2px",
  backgroundColor: "#2c2c2c",
  fontWeight: "bold",
};

function BuyDMElements({
  billingAddress,
  setBillingAddress,
  formErrors,
  setFormErrors,
  exchangeValue
}) {
  const giftCards = [ 25, 50, 100, 250, 500];
  const [otherAmountMethod, setOtherAmountMethod] = useState(false);

  function handleBillingAddressChange(field, value) {
    setBillingAddress((prev) => {
      const updatedAddress = {
        ...prev,
        [field]: value,
      };
      const updatedErrors = { ...formErrors };
      const fieldError = validateField(field, value, updatedAddress);
  
      if (!fieldError) {
        delete updatedErrors[field];
      } else {
        updatedErrors[field] = fieldError;
      }
  
      setFormErrors(updatedErrors);
      return updatedAddress;
    });
  }
  
  function validateField(field, value) {
    switch (field) {
      case "email":
        if (!value || !/\S+@\S+\.\S+/.test(value)) {
          return "Valid email is required.";
        }
        break;
  
      case "firstName":
        if (!value.trim()) {
          return "First name is required.";
        }
        break;
  
      case "lastName":
        if (!value.trim()) {
          return "Last name is required.";
        }
        break;
  
      case "country":
        if (!value.trim()) {
          return "Country is required.";
        }
        break;
  
      case "addressLine1":
        if (!value.trim()) {
          return "Address is required.";
        }
        break;
  
      case "countryCode":
        if (!value.trim()) {
          return "Country code is required.";
        } else if (!/^\+\d+$/.test(value)) {
          return "Country code must start with '+' followed by numbers.";
        }
        break;
  
      case "postalCode":
        if (!value.trim()) {
          return "Postal code is required.";
        } else if (!/^\d+$/.test(value)) {
          return "Postal code must be numeric.";
        }
        break;
  
      case "phoneNumber":
        if (value.trim() && !/^\+?\d{10,15}$/.test(value)) {
          return "Phone number must be 10-15 digits.";
        }
        break;
  
      case "amount":
        if (!value || parseFloat(value) <= 0) {
          return "Please select gift card!";
        }
        break;
  
      case "card.name":
        if (!value.trim()) {
          return "Cardholder name is required.";
        }
        break;
  
      case "card.no":
        if (!value.trim()) {
          return "Card number is required.";
        } else if (!/^\d{16}$/.test(value)) {
          return "Card number must be 16 digits.";
        }
        break;
  
      case "card.cvv":
        if (!value.trim()) {
          return "CVV is required.";
        } else if (!/^\d{3}$/.test(value)) {
          return "CVV must be 3 digits.";
        }
        break;
  
      case "card.expiry":
        if (!value.trim()) {
          return "Expiry date is required.";
        } else if (!/^\d{4}-(0[1-9]|1[0-2])$/.test(value)) {
          return "Expiry date must be in YYYY-MM format.";
        } else {
          const [year, month] = value.split("-").map(Number);
          const currentDate = new Date();
          const expiryDate = new Date(year, month - 1);
  
          if (expiryDate < currentDate) {
            return "Expiry date must be in the future.";
          }
        }
        break;
  
      default:
        return null;
    }
    return null;
  }

  return (
    <>
      <h2 style={labelStyle}>Choose your Gift Card</h2>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: "16px",
          padding: "16px",
          backgroundColor: "#121212",
          borderRadius: "8px",
          color: "#ffffff",
        }}
      >
        {giftCards.map((item, index) => (
          <button
            key={index}
            type="button"
            className={`gift-card-button-form ${
              billingAddress.amount === item ? "active" : ""
            }`}
            onClick={() => {
              handleBillingAddressChange("amount", item);
              setOtherAmountMethod(false);
            }}
          >
            ${item}
          </button>
        ))}
        <button
          className={`gift-card-button-form ${otherAmountMethod ? "active" : ""}`}
          type="button"
          onClick={() => {
            handleBillingAddressChange("amount", 0);
            setOtherAmountMethod(!otherAmountMethod);
          }}
        >
          Other
        </button>
      </div>

      {otherAmountMethod && (
        <div
          style={{
            backgroundColor: "#2c2c2c",
            margin: "0",
            padding: "4px 0px",
          }}
        >
          <label
            style={{
              backgroundColor: "#2c2c2c",
            }}
          >
            Amount ($):
          </label>
          <input
            style={{
              margin: "4px",
            }}
            type="number"
            name="amount"
            value={billingAddress.amount}
            onChange={(e) => {
              handleBillingAddressChange("amount", e.target.value);
            }}
          />
        </div>
      )}

      <span style={{ ...labelStyle, fontWeight: "bold" }}>
        Approximate DM:{" "}
        {((billingAddress.amount || 0) / exchangeValue).toFixed(2)}
      </span>
      {formErrors.amount && <span style={errorStyle}>{formErrors.amount}</span>}

      <h2 style={labelStyle}>Personal Information</h2>

      {/* Email */}
      <label style={labelStyle}>Email</label>
      <input
        type="email"
        placeholder="Email"
        value={billingAddress.email}
        onChange={(e) =>
          handleBillingAddressChange("email", e.target.value)
        }
        style={inputStyle}
      />
      {formErrors.email && (
        <span style={errorStyle}>{formErrors.email}</span>
      )}

      {/* First Name */}
      <label style={labelStyle}>First Name</label>
      <input
        type="text"
        placeholder="First Name"
        value={billingAddress.firstName}
        onChange={(e) =>
          handleBillingAddressChange("firstName", e.target.value)
        }
        style={inputStyle}
      />
      {formErrors.firstName && (
        <span style={errorStyle}>{formErrors.firstName}</span>
      )}

      {/* Last Name */}
      <label style={labelStyle}>Last Name</label>
      <input
        type="text"
        placeholder="Last Name"
        value={billingAddress.lastName}
        onChange={(e) => handleBillingAddressChange("lastName", e.target.value)}
        style={inputStyle}
      />
      {formErrors.lastName && (
        <span style={errorStyle}>{formErrors.lastName}</span>
      )}

      {/* Country */}
      <label style={labelStyle}>Country</label>
      <select
        value={billingAddress.country}
        onChange={(e) => handleBillingAddressChange("country", e.target.value)}
        style={inputStyle}
        name="country"
      >
        <option value="">Select your country*</option>
        <option value="USA">USA</option>
        <option value="India">India</option>
        <option value="Somalia">Somalia</option>
        <option value="Afghanistan">Afghanistan</option>
        <option value="Albania">Albania</option>
        <option value="Algeria">Algeria</option>
        <option value="Andorra">Andorra</option>
        <option value="Angola">Angola</option>
        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
        <option value="Argentina">Argentina</option>
        <option value="Armenia">Armenia</option>
        <option value="Australia">Australia</option>
        <option value="Austria">Austria</option>
        <option value="Azerbaijan">Azerbaijan</option>
        <option value="Bahamas">Bahamas</option>
        <option value="Bahrain">Bahrain</option>
        <option value="Bangladesh">Bangladesh</option>
        <option value="Barbados">Barbados</option>
        <option value="Belarus">Belarus</option>
        <option value="Belgium">Belgium</option>
        <option value="Belize">Belize</option>
        <option value="Benin">Benin</option>
        <option value="Bhutan">Bhutan</option>
        <option value="Bolivia">Bolivia</option>
        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
        <option value="Botswana">Botswana</option>
        <option value="Brazil">Brazil</option>
        <option value="Brunei">Brunei</option>
        <option value="Bulgaria">Bulgaria</option>
        <option value="Burkina Faso">Burkina Faso</option>
        <option value="Burundi">Burundi</option>
        <option value="Cabo Verde">Cabo Verde</option>
        <option value="Cambodia">Cambodia</option>
        <option value="Cameroon">Cameroon</option>
        <option value="Canada">Canada</option>
        <option value="Central African Republic">
          Central African Republic
        </option>
        <option value="Chad">Chad</option>
        <option value="Chile">Chile</option>
        <option value="China">China</option>
        <option value="Colombia">Colombia</option>
        <option value="Comoros">Comoros</option>
        <option value="Congo">Congo</option>
        <option value="Costa Rica">Costa Rica</option>
        <option value="Croatia">Croatia</option>
        <option value="Cuba">Cuba</option>
        <option value="Cyprus">Cyprus</option>
        <option value="Czech Republic">Czech Republic</option>
        <option value="Democratic Republic of the Congo">
          Democratic Republic of the Congo
        </option>
        <option value="Denmark">Denmark</option>
        <option value="Djibouti">Djibouti</option>
        <option value="Dominica">Dominica</option>
        <option value="Dominican Republic">Dominican Republic</option>
        <option value="East Timor (Timor-Leste)">
          East Timor (Timor-Leste)
        </option>
        <option value="Ecuador">Ecuador</option>
        <option value="Egypt">Egypt</option>
        <option value="El Salvador">El Salvador</option>
        <option value="Equatorial Guinea">Equatorial Guinea</option>
        <option value="Eritrea">Eritrea</option>
        <option value="Estonia">Estonia</option>
        <option value="Eswatini">Eswatini</option>
        <option value="Ethiopia">Ethiopia</option>
        <option value="Fiji">Fiji</option>
        <option value="Finland">Finland</option>
        <option value="France">France</option>
        <option value="Gabon">Gabon</option>
        <option value="Gambia">Gambia</option>
        <option value="Georgia">Georgia</option>
        <option value="Germany">Germany</option>
        <option value="Ghana">Ghana</option>
        <option value="Greece">Greece</option>
        <option value="Grenada">Grenada</option>
        <option value="Guatemala">Guatemala</option>
        <option value="Guinea">Guinea</option>
        <option value="Guinea-Bissau">Guinea-Bissau</option>
        <option value="Guyana">Guyana</option>
        <option value="Haiti">Haiti</option>
        <option value="Honduras">Honduras</option>
        <option value="Hungary">Hungary</option>
        <option value="Iceland">Iceland</option>
        <option value="Indonesia">Indonesia</option>
        <option value="Iran">Iran</option>
        <option value="Iraq">Iraq</option>
        <option value="Ireland">Ireland</option>
        <option value="Israel">Israel</option>
        <option value="Italy">Italy</option>
        <option value="Ivory Coast">Ivory Coast</option>
        <option value="Jamaica">Jamaica</option>
        <option value="Japan">Japan</option>
        <option value="Jordan">Jordan</option>
        <option value="Kazakhstan">Kazakhstan</option>
        <option value="Kenya">Kenya</option>
        <option value="Kiribati">Kiribati</option>
        <option value="Korea, North (North Korea)">
          Korea, North (North Korea)
        </option>
        <option value="Korea, South (South Korea)">
          Korea, South (South Korea)
        </option>
        <option value="Kosovo">Kosovo</option>
        <option value="Kuwait">Kuwait</option>
        <option value="Kyrgyzstan">Kyrgyzstan</option>
        <option value="Laos">Laos</option>
        <option value="Latvia">Latvia</option>
        <option value="Lebanon">Lebanon</option>
        <option value="Lesotho">Lesotho</option>
        <option value="Liberia">Liberia</option>
        <option value="Libya">Libya</option>
        <option value="Liechtenstein">Liechtenstein</option>
        <option value="Lithuania">Lithuania</option>
        <option value="Luxembourg">Luxembourg</option>
        <option value="Madagascar">Madagascar</option>
        <option value="Malawi">Malawi</option>
        <option value="Malaysia">Malaysia</option>
        <option value="Maldives">Maldives</option>
        <option value="Mali">Mali</option>
        <option value="Malta">Malta</option>
        <option value="Marshall Islands">Marshall Islands</option>
        <option value="Mauritania">Mauritania</option>
        <option value="Mauritius">Mauritius</option>
        <option value="Mexico">Mexico</option>
        <option value="Micronesia">Micronesia</option>
        <option value="Moldova">Moldova</option>
        <option value="Monaco">Monaco</option>
        <option value="Mongolia">Mongolia</option>
        <option value="Montenegro">Montenegro</option>
        <option value="Morocco">Morocco</option>
        <option value="Mozambique">Mozambique</option>
        <option value="Myanmar">Myanmar</option>
        <option value="Namibia">Namibia</option>
        <option value="Nauru">Nauru</option>
        <option value="Nepal">Nepal</option>
        <option value="Netherlands">Netherlands</option>
        <option value="New Zealand">New Zealand</option>
        <option value="Nicaragua">Nicaragua</option>
        <option value="Niger">Niger</option>
        <option value="Nigeria">Nigeria</option>
        <option value="North Macedonia">North Macedonia</option>
        <option value="Norway">Norway</option>
        <option value="Oman">Oman</option>
        <option value="Pakistan">Pakistan</option>
        <option value="Palau">Palau</option>
        <option value="Palestine">Palestine</option>
        <option value="Panama">Panama</option>
        <option value="Papua New Guinea">Papua New Guinea</option>
        <option value="Paraguay">Paraguay</option>
        <option value="Peru">Peru</option>
        <option value="Philippines">Philippines</option>
        <option value="Poland">Poland</option>
        <option value="Portugal">Portugal</option>
        <option value="Qatar">Qatar</option>
        <option value="Romania">Romania</option>
        <option value="Russia">Russia</option>
        <option value="Rwanda">Rwanda</option>
        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
        <option value="Saint Lucia">Saint Lucia</option>
        <option value="Saint Vincent and the Grenadines">
          Saint Vincent and the Grenadines
        </option>
        <option value="Samoa">Samoa</option>
        <option value="San Marino">San Marino</option>
        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
        <option value="Saudi Arabia">Saudi Arabia</option>
        <option value="Senegal">Senegal</option>
        <option value="Serbia">Serbia</option>
        <option value="Seychelles">Seychelles</option>
        <option value="Sierra Leone">Sierra Leone</option>
        <option value="Singapore">Singapore</option>
        <option value="Slovakia">Slovakia</option>
        <option value="Slovenia">Slovenia</option>
        <option value="Solomon Islands">Solomon Islands</option>
        <option value="Somalia">Somalia</option>
        <option value="South Africa">South Africa</option>
        <option value="South Sudan">South Sudan</option>
        <option value="Spain">Spain</option>
        <option value="Sri Lanka">Sri Lanka</option>
        <option value="Sudan">Sudan</option>
        <option value="Suriname">Suriname</option>
        <option value="Sweden">Sweden</option>
        <option value="Switzerland">Switzerland</option>
        <option value="Syria">Syria</option>
        <option value="Taiwan">Taiwan</option>
        <option value="Tajikistan">Tajikistan</option>
        <option value="Tanzania">Tanzania</option>
        <option value="Thailand">Thailand</option>
        <option value="Togo">Togo</option>
        <option value="Tonga">Tonga</option>
        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
        <option value="Tunisia">Tunisia</option>
        <option value="Turkey">Turkey</option>
        <option value="Turkmenistan">Turkmenistan</option>
        <option value="Tuvalu">Tuvalu</option>
        <option value="Uganda">Uganda</option>
        <option value="Ukraine">Ukraine</option>
        <option value="United Arab Emirates">United Arab Emirates</option>
        <option value="United Kingdom">United Kingdom</option>
        <option value="Uruguay">Uruguay</option>
        <option value="Uzbekistan">Uzbekistan</option>
        <option value="Vanuatu">Vanuatu</option>
        <option value="Vatican City">Vatican City</option>
        <option value="Venezuela">Venezuela</option>
        <option value="Vietnam">Vietnam</option>
        <option value="Yemen">Yemen</option>
        <option value="Zambia">Zambia</option>
        <option value="Zimbabwe">Zimbabwe</option>
      </select>
      {formErrors.country && (
        <span style={errorStyle}>{formErrors.country}</span>
      )}

      {/* Address */}
      <label style={labelStyle}>Address</label>
      <input
        type="text"
        placeholder="Address Line 1"
        value={billingAddress.addressLine1}
        onChange={(e) =>
          handleBillingAddressChange("addressLine1", e.target.value)
        }
        style={inputStyle}
      />
      {formErrors.addressLine1 && (
        <span style={errorStyle}>{formErrors.addressLine1}</span>
      )}

      {/* Country Code */}
      <label style={labelStyle}>Country Code</label>
      <input
        type="text"
        placeholder="Country Code"
        value={billingAddress.countryCode}
        onChange={(e) =>
          handleBillingAddressChange("countryCode", e.target.value)
        }
        style={inputStyle}
      />
      {formErrors.countryCode && (
        <span style={errorStyle}>{formErrors.countryCode}</span>
      )}

      {/* Postal Code */}
      <label style={labelStyle}>Postal Code</label>
      <input
        type="text"
        placeholder="Postal Code"
        value={billingAddress.postalCode}
        onChange={(e) =>
          handleBillingAddressChange("postalCode", e.target.value)
        }
        style={inputStyle}
      />
      {formErrors.postalCode && (
        <span style={errorStyle}>{formErrors.postalCode}</span>
      )}

      {/* Phone Number */}
      <label style={labelStyle}>Phone Number (optional)</label>
      <input
        type="tel"
        placeholder="Phone Number"
        value={billingAddress.phoneNumber}
        onChange={(e) =>
          handleBillingAddressChange("phoneNumber", e.target.value)
        }
        style={inputStyle}
      />
      {formErrors.phoneNumber && (
        <span style={errorStyle}>{formErrors.phoneNumber}</span>
      )}
    </>
  );
}

export default BuyDMElements;
